<template>
    <div ref="load" class="zt-page-content">
        <div class="fj-list">
            <Quotation v-if="item" :item="item" ref="getSku" />
        </div>
        <!-- <div class="block add-btn">
            <i class="el-icon-circle-plus-outline"></i>
            <span>继续选择附件</span>
        </div> -->
        <div v-if="item" class="zt-block block-btm">
            <div v-if="!type" class="select-container">
                <span>*</span><b>合作期限</b>
                <el-select v-model="optionsValue" placeholder="请选择">
                    <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <el-button v-if="!type" @click="skuSubmit(1)" type="primary">开始合作</el-button>
            <el-button v-if="type" @click="back()" type="primary">取消</el-button>
            <el-button v-if="type" @click="skuSubmit(0)" type="primary">保存</el-button>
        </div>
    </div>
</template>
<script>
import { Loading } from 'element-ui';
import _ from 'lodash';
import { goSignPercent } from '@/service/contract';
import { setStepPrice,factoryPercentId,marketPercentId } from '@/service/factory';
import Quotation from '@/components/factory/Quotation.vue'
export default {
    components: {
        Quotation
    },
    data() {
        return {
            options: [
                { value: 1, label: "3个月" },
                { value: 2, label: "6个月" },
                { value: 3, label: "12个月" },
                { value: 4, label: "18个月" },
                { value: 5, label: "24个月" },
                { value: 6, label: "36个月" }
            ],
            optionsValue:null,
            type:null,// type附件sku索引值
            item:null,
            itemId:null,// 附件或者商品的ID
            loading:null,
        }
    },
    created() {
        this.type = this.$route.query.type;
        this.detail();
    },
    mounted() {
        this.loading = Loading.service({
            fullscreen: true, text: '拼命加载中....',
            target:this.$refs.load
        });
    },
    methods:{
        back() {
            this.$router.go(-1);
        },
        detail() {
            if (this.$route.query.market) {
                marketPercentId(this.$route.params.id).then(rst=>{
                    if (rst) {
                        this.item=rst;
                        if (this.type && this.type>=0) {
                            this.itemId = rst.accessory[this.type].id;
                        } else {
                            this.itemId = rst.id;
                        }
                    }
                    console.log(this.item);
                });
            } else {
                factoryPercentId(this.$route.params.id).then(rst=>{
                    if (rst) {
                        this.item=rst;
                        if (this.type && this.type>=0) {
                            this.itemId = rst.accessory[this.type].id;
                        } else {
                            this.itemId = rst.id;
                        }
                    }
                    console.log(this.item);
                });
            }
        },
        submit(obj,type) {
            const _loading = this.$loading({
                lock: true,
                text: '拼命加载中......',
            });
            if (type==1) {
                goSignPercent(obj).then(rst => {
                    _loading.close();
                    this.$message.success(type==1 ? '合作成功' : '保存成功');
                    this.$router.push('/factory/hzzp');
                }).catch(err => {
                    _loading.close();
                    this.$message.error(err.message);
                });
            } else {
                setStepPrice(obj).then(rst => {
                    _loading.close();
                    this.$message.success(type==1 ? '合作成功' : '保存成功');
                    this.$router.push('/factory/hzzp');
                }).catch(err => {
                    _loading.close();
                    this.$message.error(err.message);
                });
            }
        },
        async skuSubmit(type){
            if (!this.optionsValue) {
                this.$message.error("请选择合作期限");
                return;
            }
            this.$refs.getSku.valid().then(rst => {
                if (rst && rst.length>0) {
                    let _item = _.clone(rst);
                    // console.log(_item);
                    let obj = {
                        product_no:this.itemId,
                        step_price:_item,
                    };
                    type==1 ? obj.timeval=this.optionsValue : null
                    this.submit(obj,type);
                }
            }).catch(err => {
                this.$message.error(err.message);
            });
        },
    },
    updated() {
        this.loading.close();
    },
}
</script>
<style src="../style.css"></style>
<style scoped lang="less">
    .zt-page-content {
        background: #F5F5F5;
        .add-btn {
            height: 80px;
            color: #016FFF;
            font-size: 16px;
            display: flex;
            align-items: center;
            padding: 0 46px;
            cursor: pointer;
            i {
                font-size: 18px;
                margin-right: 16px;
            }
        }
        .block-btm {
            height: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            .select-container {
                position: absolute;
                left: 50px;
                display: flex;
                align-items: center;
                span {
                    color: #D70000;
                    font-size: 22px;
                    position: relative;
                    top: 4px;
                }
                b {
                    margin: 0 23px 0 5px;
                }
            }
        }
    }
</style>